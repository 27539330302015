import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrdersChart } from 'app/@core/data/orders-chart';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ECommerceService {
  constructor(private http: HttpClient) { }

  getHeader() {
    return this.http.get(environment.API + '/dashboard/getHeader/');
  }
  getOrderCart(period: string, query) {
    return this.http.get(environment.API + '/dashboard/getOrderCart/' + period, { params: query });
  }
  getChartProfitOrder(period: string, query) {
    return this.http.get(environment.API + '/dashboard/getChartProfitOrder/' + period, { params: query });
  }
  delete(param) {
    return this.http.delete(environment.API + '/order/delete/' + param);
  }
  update(body) {
    return this.http.post(environment.API + '/order/update', body);
  }
}
