import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { catchError, retry } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppInterceptorsService implements HttpInterceptor {
  token: string;
  constructor(private route: ActivatedRoute) {
    if (sessionStorage.getItem('token') != null) {
      this.token = sessionStorage.getItem('token');
    } else {
      this.route.queryParams.subscribe(params => {
        this.token = params['key'];
        sessionStorage.setItem('token', params['key']);
        console.log(params['key']);
      });
    }
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = new HttpHeaders({
      // 'Authorization': 'Bearer ' + this.token,
      // 'Access-Control-Allow-Origin': '*',
    });

    const clone = req.clone({
      headers: headers,
    });
    return next.handle(clone).pipe(
      catchError(this.handlerError),
      retry(1),
    );
  }
  handlerError(error: HttpErrorResponse) {
    console.log(error.status);
    if (error.status === 401) {
      // alert('silahkan login kembali');
      window.location.href = '/#/login';
      sessionStorage.removeItem('session_auth');
    }
    return throwError(error);
  }
}
