import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    myMethod$: Observable<any>;
    currentTheme = new Subject<'default'>();
    constructor(private http: HttpClient) {
        this.currentTheme.next('default');
        this.myMethod$ = this.currentTheme.asObservable();
    }

    setTheme(data) {
        this.currentTheme.next(data);
    }

}
